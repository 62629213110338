export type SiteConfig = typeof siteConfig;

export const WP_REST_BASE_URL =
  "https://blog.mubarokah.com/wp-json/wp/v2";

export const siteConfig = {
  name: "Mubarokah: Digital Creative Agency",
  description:
    "Mubarokah Digital Creative Agency specializes in web development, digital marketing, mobile app development, and more. Our innovative solutions help businesses enhance their online presence and achieve their goals. Discover how we can transform your digital strategy and drive success.",
    keywords: [
      "web development",
      "mobile app development",
      "digital marketing",
      "UI/UX design",
      "branding",
      "e-commerce development",
      "content marketing",
      "SEO",
      "social media marketing",
      "data analytics",

      "custom web development services",
      "mobile app development for startups",
      "digital marketing strategy for small businesses",
      "responsive web design",
      "user experience design",
      "search engine optimization services",
      "social media advertising campaigns",
      "data-driven marketing solutions",

      "healthcare app development",
      "e-commerce website desig",
      "fintech solutions",

      "innovative digital solutions",
      "scalable web applications",
      "results-driven marketing campaigns",
      "Digital Agency",
      "offshore development team",
    ],    
  url: "https://mubarokah.com/",
  ogImage: "https://mubarokah-landing-page.vercel.app/GraphImageWebMubarokah.webp",
};

export const mainNav = [
  {
    title: "Home",
    href: "/",
  },
  {
    title: "Services",
    href: "/services/",
  },
  {
    title: "Pricing",
    href: "/pricing/",
  },
  {
    title: "Pages",
    items: [
      {
        title: "About",
        href: "/about/",
        items: [],
      },
      {
        title: "Projects",
        href: "/projects/",
        items: [],
      },
      {
        title: "Blog",
        href: "/posts/",
        items: [],
      },
      {
        title: "Contact",
        href: "/contact/",
        items: [],
      },
      {
        title: "Muna Assistant",
        href: "/muna-assistant/",
        items: [],
      },
    ],
  },
  {
    title: "Resources",
    items: [
      {
        title: "WhatsApp Link Generator",
        href: "/whatsapp-link-generator/",
        items: [],
      },
      {
        title: "Business Name Generator",
        href: "/business-name-generator/",
        items: [],
      },
      {
        title: "Our Products",
        href: "https://mudin.myr.id/",
        items: [],
      },
    ],
  },
  
] satisfies MainNavItem[];

export const footerNav2 = [
  {
    title: "Solutions",
    items: [
      {
        title: "Web Development",
        href: "/services/web-design-development/",
        external: false,
      },
      {
        title: "Digital Marketing",
        href: "/services/digital-marketing/",
        external: false,
      },
      {
        title: "Content Creator",
        href: "/services/content-creator/",
        external: false,
      },
      {
        title: "Mobile App Development",
        href: "/services/mobile-app-development/",
        external: false,
      },
      {
        title: "UI/UX Designer",
        href: "/services/ui-ux-designer/",
        external: false,
      },
      {
        title: "Graphics Designer",
        href: "/services/graphics-designer/",
        external: false,
      },
      {
        title: "SEO Specialist",
        href: "/services/seo/",
        external: false,
      },
      {
        title: "Hosting, VPS/Cloud",
        href: "/services/hosting/",
        external: false,
      },
      {
        title: "WhatsApp Business API",
        href: "/services/whatsapp-business-api/",
        external: false,
      },
    ],
  },
  {
    title: "Quick Links",
    items: [
      {
        title: "Services",
        href: "/services/",
        external: false,
      },
      {
        title: "Portfolio",
        href: "/projects/",
        external: false,
      },
      {
        title: "Pricing",
        href: "/pricing/",
        external: false,
      },
      {
        title: "WhatsApp Link Generator",
        href: "/whatsapp-link-generator/",
        external: false,
      },
      {
        title: "Business Name Generator",
        href: "/business-name-generator/",
        external: false,
      },
      {
        title: "System Status",
        href: "https://mdn.cronitorstatus.com/",
        external: true,
      },
      {
        title: "Our Products",
        href: "https://mudin.myr.id/",
        external: true,
      },
    ],
  },
  {
    title: "Company",
    items: [
      {
        title: "About Company",
        href: "/about/",
        external: false,
      },
      {
        title: "Blog",
        href: "/posts/",
        external: false,
      },
      {
        title: "Contact",
        href: "/contact/",
        external: false,
      },
      {
        title: "Terms of Service",
        href: "/tos/",
        external: false,
      },
      {
        title: "Privacy Policy",
        href: "/privacy/",
        external: false,
      },
      {
        title: "Refund Policy",
        href: "/refund-policy/",
        external: false,
      },
      {
        title: "Muna Assistant",
        href: "/muna-assistant/",
        external: false,
      },
    ],
  },
] satisfies FooterItem[];
