import dynamic from "next/dynamic";
import { siteConfig } from "@/config/site";

type SiteLogoProps = {
  width: number;
  height: number;
  lightClasses?: string;
  darkClasses?: string;
};

const DynamicImage = dynamic(() => import("next/image"), { ssr: false });

export default function SiteLogo({
  width,
  height,
  lightClasses,
  darkClasses,
}: SiteLogoProps) {
  return (
    <>
      <DynamicImage
        src="/logo-light.svg"
        className={lightClasses}
        width={width}
        height={height}
        alt={siteConfig.name}
        loading="lazy"
        style={{ width: "auto", height: "auto" }}
      />
      <DynamicImage
        src="/logo-white.svg"
        className={darkClasses}
        width={width}
        height={height}
        alt={siteConfig.name}
        loading="lazy"
        style={{ width: "auto", height: "auto" }}
      />
    </>
  );
}
