import { z } from "zod";
import isMobilePhone from "validator/lib/isMobilePhone";

export const NewsletterFormSchema = z.object({
  name: z.string().optional(),
  email: z.string().email(),
});

export const ContactFormSchema = z.object({
  name: z.string().trim().min(1, "Name is required").max(255),
  namaperusahaan: z.string().min(1, "Company Name is required."),
  jabatan: z.string().min(1, "Job Title is required."),
  email: z.string().email("Invalid email.").min(1, "Email is required."),
  message: z.string().min(12, "Message must be at least 12 characters."),
});

// export const QuotationFormSchema = z.object({
//   name: z.string().min(1, "Name is required."),
//   companyname: z.string().min(1, "Company Name is required."),
//   jobtitle: z.string().min(1, "Job Title is required."),
//   email: z.string().email("Invalid email.").min(1, "Email is required."),
//   phone: z.string().refine(isMobilePhone, "Invalid phone number").optional(),
//   website: z
//     .string()
//     .url("The URL must start with http:// or https://")
//     .min(1, "Website is required."),
//   service: z.string().min(1, "Service is required."),
//   message: z.string().min(1, "Message is required."),
// });

export const QuotationFormSchema = z.object({
  name: z.string().min(1, "Name is required."),
  companyname: z.string().optional(), // Opsional
  jobtitle: z.string().optional(), // Opsional
  email: z.string().email("Invalid email.").min(1, "Email is required."),
  phone: z.string().refine(isMobilePhone, "Your phone number or WhatsApp is invalid. And this field is required."),
  website: z.string().optional().refine((value) => !value || value.startsWith("http://") || value.startsWith("https://"), {
    message: "The URL must start with http:// or https://",
  }), // Opsional dan hanya divalidasi jika diisi
  service: z.string().min(1, "Service is required."),
  message: z.string().min(1, "Message is required."),
});


export const SearchFormSchema = z.object({
  query: z.string().optional(),
});
